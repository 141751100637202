import React, { FunctionComponent, useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  withStyles
} from "@material-ui/core";
import { getAllTeams } from "../../Axios";
import styles from "./styles";

type Props = {
  open: boolean;
  toggleOpen: any;
  handleConnect?: any;
  classes: any;
};

const ConnectTeamsModal: FunctionComponent<Props> = props => {
  const { open, toggleOpen, handleConnect, classes } = props;
  const [teams, setTeams] = useState<any>([]);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const handleCheck = (event, team) => {
    event.target.checked ? setSelectedTeam(team) : setSelectedTeam(null);
  };

  useEffect(() => {
    getAllTeams().then((data: any) => setTeams(data.value));
  }, []);

  return (
    <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={toggleOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Microsoft Teams</DialogTitle>
      <DialogContent>
        <DialogContentText>Select team ({selectedTeam?.displayName} selected)</DialogContentText>
        <table className={classes.table}>
          {teams.map(team => (
            <tr key={team.id}>
              <td>
                <Typography>{team.displayName}</Typography>
              </td>
              <td>
                <Checkbox checked={selectedTeam?.id === team.id} onChange={e => handleCheck(e, team)} />
              </td>
            </tr>
          ))}
        </table>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleOpen} color="primary">
          Cancel
        </Button>
        <Button disabled={!selectedTeam} onClick={() => handleConnect(selectedTeam)} color="primary">
          Connect
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ConnectTeamsModal);
