import TextField from "@material-ui/core/TextField";
import React, { FunctionComponent } from "react";
import styles from './Styles'
import { withStyles } from "@material-ui/core";
import { NumberFormatCustom, capitalize } from "../../util/functions";

type Props = {
  field: string,
  placeholder?: string,
  value?: number | string,
  handleChange: Function,
  classes: any,
  format?: boolean
}

const NumberComponent: FunctionComponent<Props> = ({ field, placeholder, value, handleChange, classes, format = true }) => {
  return (
    <TextField
      id={field}
      label={placeholder ?? capitalize(field.split('_').join(" "))}
      className={classes.input}
      variant="outlined"
      onChange={(e) => handleChange(field, e.target.value)}
      value={value ? value : ""}
      fullWidth
      InputProps={{
        ...(format && { inputComponent: NumberFormatCustom }),
      }}
    />
  )
};


export default withStyles(styles)(NumberComponent);