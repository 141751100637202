import React, { FunctionComponent } from "react";
import styles from "./Styles";
import { withStyles } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { capitalize } from "../../util/functions";

type Props = {
  field: string;
  label?: string;
  value?: Date;
  handleChange: Function;
  classes: any;
};

const DateComponent: FunctionComponent<Props> = ({ field, label, value, handleChange, classes }) => {
  return (
    <MuiPickersUtilsProvider utils={LuxonUtils}>
      <KeyboardDatePicker
        id={field}
        variant="inline"
        format={"dd/MM/yyyy"}
        inputVariant={"outlined"}
        className={classes.input}
        label={label ?? capitalize(field.replace(/_/g, " "))}
        value={value ?? null}
        onChange={date => handleChange(field, date)}
      />
    </MuiPickersUtilsProvider>
  );
};

export default withStyles(styles)(DateComponent);
