export const constants = {
  ADD_ERROR: "ADD_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",

  ADD_MESSAGE: "ADD_MESSAGE",
  CLEAR_MESSAGE: "CLEAR_MESSAGE",

  ADD_SAVED: "ADD_SAVED",
  CLEAR_SAVED: "CLEAR_SAVED",
  CLEAR_ALL_SAVED: "CLEAR_ALL_SAVED",
  SET_USER: "SET_USER",
  CLEAR_USER: "CLEAR_USER",
  TOGGLE_COLLAPSE_SEARCH: "TOGGLE_COLLAPSE_SEARCH"
};

export const variants = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR"
};

export const setUser = user => ({
  type: constants.SET_USER,
  payload: user
});

export const clearUser = () => ({
  type: constants.CLEAR_USER
});

export const addError = message => ({
  type: constants.ADD_ERROR,
  payload: message
});

export const clearError = index => ({
  type: constants.CLEAR_ERROR,
  payload: index
});

export const addMessage = (message, variant) => ({
  type: constants.ADD_MESSAGE,
  payload: {
    message: message,
    variant: variant
  }
});

export const clearMessage = index => ({
  type: constants.CLEAR_MESSAGE,
  payload: index
});

export const addSavedProject = id => ({
  type: constants.ADD_SAVED,
  payload: id
});

export const clearSavedProject = id => ({
  type: constants.CLEAR_SAVED,
  payload: id
});

export const clearAllSavedProject = () => ({
  type: constants.CLEAR_ALL_SAVED
});

export const toggleCollapseSearch = () => ({
  type: constants.TOGGLE_COLLAPSE_SEARCH
});
