const styles = theme => ({
  input: {
    margin: theme.spacing(),
    flexBasis: 250,
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  }
});

export default styles;
