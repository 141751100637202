import React, { FunctionComponent, useState, useCallback } from "react";
import { withStyles, createStyles, Theme } from "@material-ui/core/styles/index";
import Table from "@material-ui/core/Table/index";
import TableBody from "@material-ui/core/TableBody/index";
import TableCell from "@material-ui/core/TableCell/index";
import TableHead from "@material-ui/core/TableHead/index";
import TablePagination from "@material-ui/core/TablePagination/index";
import TableRow from "@material-ui/core/TableRow/index";
import TableSortLabel from "@material-ui/core/TableSortLabel/index";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip/index";
import { getDate } from "../util/functions";
import Typography from "@material-ui/core/Typography/index";
import Toolbar from "@material-ui/core/Toolbar/index";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardArrowLeft, KeyboardArrowRight, AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { makeStyles, useTheme, ButtonGroup, Button } from "@material-ui/core";
import { connect } from "react-redux";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import Loader from "./Loader";
import { pad } from "../util/functions";
import { addSavedProject, clearSavedProject } from "../redux/Actions";
import { IProject } from "../interfaces/IProject";
import { EStatus } from "../enums/EStatus";
import { Link, useHistory } from "react-router-dom";
import ExportButton from "./ExportButton";

function desc(a: IProject, b: IProject, orderBy: string) {
  if (!orderBy) return 1;

  if (!a[orderBy]) return 1;
  if (!b[orderBy]) return -1;

  if (orderBy === "project_number") {
    if (pad(b.year, 3) + "" + pad(b[orderBy], 3) < pad(a.year, 3) + "" + pad(a[orderBy], 3)) return -1;
    if (pad(b.year, 3) + "" + pad(b[orderBy], 3) > pad(a.year, 3) + "" + pad(a[orderBy], 3)) return 1;
  }
  if (orderBy === "contract_value") {
    if (!a.total_contract_value?.find(item => item.currency == "DKK")?.value) return 1;
    if (!b.total_contract_value?.find(item => item.currency == "DKK")?.value) return -1;

    if (
      (a.total_contract_value?.find(item => item.currency == "DKK")?.value || 0) >
      (b.total_contract_value?.find(item => item.currency == "DKK")?.value || 0)
    )
      return -1;
    else return 1;
  }

  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "project_number",
    show: true,
    numeric: false,
    disablePadding: false,
    label: "Project Nr."
  },
  {
    id: "title",
    show: true,
    numeric: false,
    disablePadding: false,
    label: "Title"
  },
  {
    id: "country",
    show: true,
    numeric: true,
    disablePadding: false,
    label: "Country"
  },
  {
    id: "donor",
    show: true,
    numeric: true,
    disablePadding: false,
    label: "Donor"
  },
  {
    id: "assignment_type",
    show: true,
    numeric: true,
    disablePadding: false,
    label: "Assignment Type"
  },
  {
    id: "contract_value",
    show: true,
    numeric: true,
    disablePadding: false,
    label: "Contract Value (DKK)"
  },
  {
    id: "sector",
    show: true,
    numeric: true,
    disablePadding: false,
    label: "Sector"
  },
  {
    id: "start_date",
    show: true,
    numeric: true,
    disablePadding: false,
    label: "Start Date"
  },
  {
    id: "end_date",
    show: true,
    numeric: true,
    disablePadding: false,
    label: "End Date"
  },
  {
    id: "status",
    show: true,
    numeric: true,
    disablePadding: false,
    label: "Status"
  }
];

type Props = {
  order: "desc" | "asc" | undefined;
  orderBy: false | "desc" | "asc" | undefined;
  rowCount: number;
  clearOrderBy: any;
  onRequestSort: Function;
};

const TableHeader: FunctionComponent<Props> = ({ order, orderBy, rowCount, clearOrderBy, onRequestSort }) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding={"none"} align={"right"}>
          {orderBy && (
            <IconButton onClick={clearOrderBy}>
              <ClearAllIcon />
            </IconButton>
          )}
        </TableCell>

        {rows.map(row => {
          return (
            <TableCell
              key={row.id}
              align={row.numeric ? "right" : "left"}
              padding={row.disablePadding ? "none" : "default"}
              sortDirection={orderBy === row.id ? order : false}
            >
              <Tooltip title="Sort" placement={row.numeric ? "bottom-end" : "bottom-start"} enterDelay={300}>
                <TableSortLabel active={orderBy === row.id} direction={order} onClick={createSortHandler(row.id)}>
                  {row.label}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5)
  }
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0);
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1);
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1);
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: spacing(3)
    },
    table: {
      minWidth: 1020
    },
    tableWrapper: {
      overflowX: "auto"
    },
    spacer: {
      flex: "1 1 100%"
    },
    actions: {
      color: palette.text.secondary,
      flex: "none"
    },
    row: {
      display: "flex",
      justifyContent: "space-between"
    },
    exportButtonDiv: {
      display: "flex",
      flexDirection: "column",
      marginLeft: spacing(3),
      justifyContent: "center"
    },
    title: {
      flex: "0 0 auto"
    },
    won: {
      //backgroundColor: Green[200]
    }
  });

const mapStateToProps = state => {
  return {
    savedProjects: state.savedProjects
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSave(id) {
      dispatch(addSavedProject(id));
    },
    onClear(id) {
      dispatch(clearSavedProject(id));
    }
  };
};

type TableProps = {
  classes: any;
  projects: IProject[];
  loading: boolean;
  search?: any;
  savedProjects: any[];
  onSave: Function;
  onClear: Function;
};

const ProjectsTable: FunctionComponent<TableProps> = ({
  classes,
  projects,
  loading,
  search,
  savedProjects,
  onSave,
  onClear
}) => {
  const [order, setOrder] = useState<"desc" | "asc">("desc");
  const [orderBy, setOrderBy] = useState<false | "desc" | "asc" | undefined>();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [filter, setFilter] = useState<EStatus | null>(EStatus.won);
  const history = useHistory();

  const toggleFilter = useCallback(
    (p: IProject) => {
      switch (filter) {
        case EStatus.won: {
          return p.status === EStatus.won;
        }
        case EStatus.inProgress: {
          return p.status === EStatus.inProgress;
        }
        case EStatus.dropped: {
          return p.status === EStatus.dropped;
        }
        case EStatus.lost: {
          return p.status === EStatus.lost;
        }
        default: {
          return p;
        }
      }
    },
    [filter]
  );

  const shownProjects: IProject[] = projects.filter(project => toggleFilter(project));

  const handleRequestSort = (event, property) => {
    let orderByTemp = property;
    let orderTemp: "asc" | "desc" = "desc";

    if (orderBy === property && order === "desc") orderTemp = "asc";

    setOrder(orderTemp);
    setOrderBy(orderByTemp);
    setPage(0);
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const clearOrderBy = () => {
    setOrderBy(false);
  };

  const handleClick = id => {
    history.push("/projects/" + id);
  };

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, projects.length - page * rowsPerPage);

  return (
    <div className={"row"}>
      <Paper className={classes.root}>
        <Toolbar>
          <div className={classes.title}>
            <Typography variant="h6">Projects {search && "Search: " + search}</Typography>
          </div>
          <div className={classes.spacer} />
          <div className={classes.actions}>
            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
              {Object.keys(EStatus).map(key => (
                <Button
                  key={key}
                  variant={filter === EStatus[key] ? "contained" : "text"}
                  onClick={() => setFilter(EStatus[key])}
                >
                  {EStatus[key]}
                </Button>
              ))}
              <Button variant={filter == null ? "contained" : "text"} onClick={() => setFilter(null)}>
                All
              </Button>
            </ButtonGroup>
            {/* <Filter handleChange={handleChange} /> */}
          </div>
        </Toolbar>
        <div className={classes.tableWrapper}>
          <Loader loading={loading}>
            {projects?.length ? (
              <Table className={classes.table} aria-labelledby="tableTitle">
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={projects.length}
                  clearOrderBy={clearOrderBy}
                />
                <TableBody>
                  {stableSort(shownProjects, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((project: IProject) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={project._id}>
                          <TableCell padding={"none"} align="right">
                            {!savedProjects.includes(project._id) ? (
                              <IconButton
                                onClick={() => onSave(project._id)}
                                className={classes.button}
                                aria-label="delete"
                              >
                                <AddCircleOutline />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => onClear(project._id)}
                                className={classes.button}
                                aria-label="delete"
                              >
                                <RemoveCircleOutline />
                              </IconButton>
                            )}
                          </TableCell>

                          <Link to={"/projects/" + project._id}>
                            <TableCell>
                              <span>
                                {project.year +
                                  "-" +
                                  pad(project.project_number) +
                                  (project.owner === "Denmark" ? "DK" : "SE")}
                              </span>
                            </TableCell>

                            <TableCell component="th" scope="row">
                              {project.title}
                            </TableCell>
                            <TableCell align="right">{project.country?.join(", ")}</TableCell>
                            <TableCell align="right">{project.donor}</TableCell>
                            <TableCell align="right">{project.assignment_type}</TableCell>
                            <TableCell align="right">
                              {project.total_contract_value?.find(item => item.currency === "DKK")?.value}
                            </TableCell>
                            <TableCell align="right">{project.sector}</TableCell>
                            <TableCell align="right">{getDate(project.start_date)}</TableCell>
                            <TableCell align="right">{getDate(project.end_date)}</TableCell>
                            <TableCell align="right">{project.status}</TableCell>
                          </Link>
                        </TableRow>
                      );
                    })}
                  {/*{emptyRows > 0 && (*/}
                  {/*<TableRow style={{height: 49 * emptyRows}}>*/}
                  {/*<TableCell colSpan={6}/>*/}
                  {/*</TableRow>*/}
                  {/*)}*/}
                </TableBody>
              </Table>
            ) : (
              <Toolbar>
                <div className={classes.title}>
                  <Typography variant="body1">No projects Found</Typography>
                </div>
              </Toolbar>
            )}
          </Loader>
        </div>

        <div className={classes.row}>
          <div className={classes.exportButtonDiv}>
            <ExportButton projects={shownProjects} />
          </div>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={shownProjects.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </div>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProjectsTable));
