import { createStore } from "redux";
import Reducers from "./Reducers";

const initialState = localStorage["project-database-store"] ? JSON.parse(localStorage["project-database-store"]) : {};

const saveState = () => (localStorage["project-database-store"] = JSON.stringify(store.getState()));

const store = createStore(Reducers, initialState);

store.subscribe(saveState);

export default store;
