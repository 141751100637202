import React, { FunctionComponent } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

type Props = {
  open: boolean;
  toggleOpen: any;
  handleDisconnect?: any;
};

export const DisconnectTeamsModal: FunctionComponent<Props> = props => {
  const { open, toggleOpen, handleDisconnect } = props;

  return (
    <Dialog open={open} onClose={toggleOpen} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Disconnect Team</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you wish to disconnect team?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleOpen} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDisconnect} color="primary">
          Disconnect
        </Button>
      </DialogActions>
    </Dialog>
  );
};
