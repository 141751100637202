import Red from "@material-ui/core/colors/red";
import Pink from "@material-ui/core/colors/pink";
import Blue from "@material-ui/core/colors/blue";
import Purple from "@material-ui/core/colors/purple";

import { createStyles, Theme } from "@material-ui/core";

export const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      paddingTop: spacing(2)
    },
    paper: {
      padding: spacing(4)
    },
    headerContainer: {
      display: "flex",
      alignItems: "center"
    },
    alignLeft: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center"
    },
    alignRight: {
      display: "flex",
      flexGrow: 1,
      alignItems: "center",
      justifyContent: "flex-end"
    },
    title: {
      lineHeight: 1.5,
      //textTransform: 'uppercase'
      display: "initial"
    },
    row: {
      paddingTop: spacing(3)
    },
    infoRow: {
      display: "flex",
      flexWrap: "wrap",
      // justifyContent: "space-between",
      paddingTop: spacing(3)
    },
    itemRow: {
      paddingTop: spacing(),
      paddingBottom: spacing(),
      display: "flex",
      alignItems: "baseline",
      flexWrap: "wrap"
    },
    item: {
      // flexGrow: 1,
      flexBasis: 300,
      marginBottom: spacing(2)
      // maxWidth: '25%'
    },
    floatRight: {
      float: "right"
    },
    button: {
      "&:hover": {
        color: palette.common.white
      }
    },
    subaligment: {
      width: "100%",
      padding: " 0 16px"
    },

    date: {
      float: "right"
    },
    cardContent: {
      padding: 12,
      "&:last-child": {
        paddingBottom: 14
      }
    },
    buttonProgress: {
      color: Pink[500],
      position: "absolute",
      top: "25%",
      left: "25%",
      marginTop: -14,
      marginLeft: -14
    },
    error: {
      color: Red[500]
    },
    content: {
      whiteSpace: "pre-line"
    },
    chip: {
      marginRight: spacing(1)
    },
    tdcell: {
      width: "33.33%",
      paddingLeft: 0,
      paddingRight: 0
    },
    blue: {
      background: Blue[700],
      color: palette.common.white,
      cursor: "pointer"
    },
    purple: {
      color: palette.common.white,
      cursor: "pointer"
    },
    file: {
      color: palette.primary.main,
      textDecoration: "underline",
      "&:hover": {
        color: palette.primary.light,
        textDecoration: "underline"
      }
    }
  });
