import React, { useState } from "react";
import { AppBar, Toolbar, Typography, withStyles, IconButton, Badge, Menu, MenuItem } from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { AccountCircle, Bookmark } from "@material-ui/icons";
import { logOut } from "../Axios";

const styles = theme => ({
  header: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    flexGrow: 1
  },
  iconButton: {
    color: theme.palette.common.white
  }
});

const mapStateToProps = state => {
  return {
    count: state.savedProjects.length,
    user: state.user
  };
};

const Header = props => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    logOut();
  };

  const { classes, count, user } = props;
  const open = Boolean(anchorEl);

  return (
    <AppBar className={classes.header} color={"primary"} position="static">
      <Toolbar>
        <Link to={"/"}>
          <Typography className={classes.header} variant="h6">
            Project Database
          </Typography>
        </Link>
        {user && (
          <div>
            <Link to="/saved">
              <IconButton className={classes.iconButton}>
                <Badge badgeContent={count}>
                  <Bookmark />
                </Badge>
              </IconButton>
            </Link>

            <IconButton className={classes.iconButton} onClick={handleMenu}>
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              className={classes.menu}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              PaperProps={{
                style: {
                  width: 200
                }
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={handleClose}
            >
              {/*<Link to ='/profile' ><MenuItem onClick={this.handleClose}>Profile</MenuItem></Link>*/}
              <MenuItem onClick={handleLogOut}>Log out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(Header));
