import React, { FunctionComponent, useCallback, useRef } from "react";
import { Button } from "@material-ui/core";
import { IProject } from "../../interfaces/IProject";
import { getProjectNumber, getDate, stripHTML } from "../../util/functions";

const csvHeaders = [
  "Project Number",
  "Title",
  "Status",
  "Status Reason",
  "Status Comment",
  "Project Manager",
  "Partner Anchor",
  "Team Leader",
  "Donor",

  "Staff Provided",
  "Country",
  "Assignment Type",
  "Sector",

  "Region",
  "Proportion",
  "Working Days",
  "Contract Value (DKK)",

  "Start Date",
  "End Date",
  "Contract Signed Date",

  "Created Date",
  "Created By",

  "General Description",
  "Types of Services"
];

type Props = {
  projects: IProject[];
};

const ExportButton: FunctionComponent<Props> = ({ projects }) => {
  const buttonEl = useRef(null);

  const handleClick = useCallback(() => {
    buttonEl?.current?.setAttribute("disabled", true);

    let csvString;

    csvString = "\uFEFF";
    csvString += csvHeaders?.join(";") + "\n";

    projects.map(project => {
      csvString += getProjectNumber(project.project_number, project.year, project.owner);
      csvString += ";";
      csvString += (project.title?.replace(/\n+/gi, " ").replace(/\r+/gi, " ") ?? "") + ";";
      csvString += (project.status ?? "") + ";";
      csvString += (project.status_reason ?? "") + ";";
      csvString += (project.status_comment ?? "") + ";";
      csvString += (project.project_manager ?? "") + ";";
      csvString += (project.partner_anchor ?? "") + ";";
      csvString += (project.team_leader ?? "") + ";";
      csvString += (project.donor ?? "") + ";";

      csvString += project.staff_provided?.join() + ";";
      csvString += project.country?.join() + ";";
      csvString += project.assignment_type?.join() + ";";
      csvString += project.sector?.join() + ";";

      csvString += (project.region ?? "") + ";";
      csvString += (project.proportion ?? "") + ";";
      csvString += (project.working_days ?? "") + ";";
      csvString += (project.total_contract_value?.find(item => item.currency === "DKK")?.value ?? "") + ";";

      csvString += getDate(project.start_date) + ";";
      csvString += getDate(project.end_date) + ";";
      // csvString += getDate(project.contract_signed_date) + ";";

      csvString += getDate(project.created_date) + ";";
      csvString += (project.created_by?.first_name ?? "") + (project.created_by?.last_name ?? "") + ";";

      csvString += (stripHTML(project.general_description?.replace(/\n+/gi, " ").replace(/\r+/gi, " ")) ?? "") + ";";
      csvString += (stripHTML(project.types_of_services?.replace(/\n+/gi, " ").replace(/\r+/gi, " ")) ?? "") + ";";

      csvString += "\n"; // new line
    });

    // csvString = "\uFEFF " + csvString; // important BOM

    var csvData = new Blob([csvString], { type: "text/csv;charset=utf-16;" });
    var csvURL = null;
    if (navigator.msSaveBlob) {
      // for IE compatibility, see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      csvURL = navigator.msSaveBlob(csvData, "projects.csv");
    } else {
      csvURL = window.URL.createObjectURL(csvData);
    }
    var tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "projects.csv");
    tempLink.click();

    buttonEl?.current?.removeAttribute("disabled");
  }, [projects]);

  return (
    <Button ref={buttonEl} onClick={handleClick} variant="contained" color="primary">
      Export {projects.length} Items
    </Button>
  );
};

export default ExportButton;
