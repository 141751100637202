import React, { useEffect, useState } from "react";
import { FilePond } from "react-filepond";
import Axios from "axios";
import { api } from "../../util/constants";
import "filepond/dist/filepond.min.css";

const FileUpload = ({ id = null, data = [], handleUpdate = (property, value) => {} }) => {
  const [files, setFiles] = useState<any>([]);

  useEffect(() => {
    setFiles(
      data.map(file => ({
        source: file,
        options: {
          type: "local"

          // file: {
          //     name: file,
          //     size: 3001025,
          // }
        }
      }))
    );
  }, []);

  useEffect(() => {
    handleUpdate && handleUpdate("filepond", files);
  }, [files]);

  return (
    <FilePond
      files={files}
      server={{
        url: api + "/uploads/" + (id ? id : ""),
        process: {
          withCredentials: true
        },
        revert: (uniqueFileId, load, error) => {
          Axios.delete(api + "/uploads/" + (id ? id : "") + "?remove=" + encodeURI(uniqueFileId))
            .then(() => load())
            .catch(() => error());
        },
        load: {
          withCredentials: true
        },
        remove: (uniqueFileId, load, error) => {
          Axios.delete(api + "/uploads/" + (id ? id : "") + "?remove=" + encodeURI(uniqueFileId))
            .then(() => load())
            .catch(() => error());
        }
      }}
      allowMultiple={true}
      maxFiles={5}
      // onprocessfilestart={file => {
      //   console.log("onprocessfile: file: ", file);
      //   setFiles([...files, file]);
      // }}
      // onprocessfilerevert={file => {
      //   setFiles(files.filter(fileitem => fileitem !== file));
      // }}
      // onprocessfile={(error, file) => {
      //   console.log("onprocessfile: file: ", file);
      //   setFiles([...files, file]);
      // }}
      onupdatefiles={fileItems => {
        console.log(fileItems.map(file => file.file.name));
        setFiles(fileItems);
      }}
    />
  );
};

export default FileUpload;
