import Axios from "axios";
import { api } from "./util/constants";
import { addMessage, clearUser, setUser } from "./redux/Actions";
import Store from "./redux/Store";
import { IProject } from "./interfaces/IProject";
Axios.defaults.withCredentials = true;

/**
 * PROJECTS
 */

export function searchProjects(query, callback) {
  Axios.post(api + "/projects/Search", query)
    .then(resp => callback && callback(null, resp.data))
    .catch(error => handleError(error));
}

export function getProjectFilters(callback) {
  Axios.get(api + "/projects/filters")
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function getProjects(callback, list: string[] = []) {
  Axios.get(api + "/projects", {
    params: list
  })
    .then(resp => callback && callback(resp.data, null))
    .catch(error => handleError(error));
}

export function getProject(id, callback) {
  Axios.get(api + "/projects/" + id)
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function createProject(project) {
  return new Promise((resolve, reject) => {
    Axios.post(api + "/projects", { project: project })
      .then(resp => {
        Store.dispatch(addMessage("Project created", "SUCCESS"));
        resolve(resp.data);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function updateProject(project) {
  return new Promise((resolve, reject) => {
    Axios.put(api + "/projects/" + project._id, { project })
      .then(resp => {
        Store.dispatch(addMessage("Project updated", "SUCCESS"));
        resolve(resp.data);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function deleteProject(id, callback) {
  Axios.delete(api + "/projects/" + id, { data: id })
    .then(resp => {
      Store.dispatch(addMessage("Project deleted", "SUCCESS"));
      callback && callback(resp.status);
    })
    .catch(error => handleError(error));
}

export function createTeam(project, owners) {
  return new Promise((resolve, reject) => {
    Axios.post(api + "/graph/teams", { project, owners })
      .then(resp => {
        Store.dispatch(addMessage("Team created", "SUCCESS"));
        resolve(resp.data);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function getTenantUsers() {
  return new Promise((resolve, reject) => {
    Axios.get(api + "/graph/users")
      .then(resp => resolve(resp.data))
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function getAllTeams() {
  return new Promise((resolve, reject) => {
    Axios.get(api + "/graph/teams")
      .then(resp => {
        resolve(resp.data);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function disconnectTeam(project: IProject) {
  return new Promise((resolve, reject) => {
    Axios.post(api + "/graph/teams/disconnect", { project })
      .then(resp => {
        Store.dispatch(addMessage("Team disconnected", "SUCCESS"));
        resolve(resp.data);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export function connectTeam(project: IProject, team) {
  return new Promise((resolve, reject) => {
    Axios.post(api + "/graph/teams/connect", { project, team })
      .then(resp => {
        Store.dispatch(addMessage("Team connected", "SUCCESS"));
        resolve(resp.data);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

/**
 * Users
 */

export function createUser(user, callback) {
  Axios.post(api + "/users", { user: user })
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function updateUser(user, callback) {
  Axios.put(api + "/users/" + user._id, { user })
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function getUsers(callback) {
  Axios.get(api + "/users")
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function getCurrentUser(callback) {
  Axios.get(api + "/users/me")
    .then(resp => callback && callback(resp.data))
    .catch(error => handleError(error));
}

export function login(user) {
  return new Promise((resolve, reject) => {
    Axios.post(api + "/users/login", { email: user.email, password: user.password })
      .then(resp => {
        Store.dispatch(setUser(resp.data.user));
        resolve(resp.data);
      })
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
}

export const logOut = () => {
  document.cookie = "ncgtok= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  Store.dispatch(clearUser());
  window.location.href = "/login";
};

function handleError(error) {
  if (!error.response) {
    // network error
    console.log("Error: ", "Network error");
    // window.location.replace("/not-found")
  } else if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 503) {
      // window.location.replace("/not-found")
    }
    if (error.response.status === 404) {
      window.location.href = "/not-found";
    } else if (error.response.status === 403) {
      Store.dispatch(clearUser());
      document.cookie = "ncgtok= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      console.log(window.location.pathname);
      if (window.location.pathname.length) {
        // window.location.href = "/login?redirect=" + window.location.pathname;
      } else {
        // window.location.href = "/login";
      }
    } else {
      if (error.response.data.message) Store.dispatch(addMessage(error.response.data.message, "ERROR"));
      else Store.dispatch(addMessage(error.response.data[Object.keys(error.response.data)[0]].message, "ERROR"));
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
    //window.location.replace("/not-found")
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("Error", error.message);
    //window.location.replace("/not-found")
  }
  //window.location.replace("/not-found")
  console.log("Error: ", error.config);
}
