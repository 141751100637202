import React, { useState } from "react";
import { withStyles, Theme, createStyles } from "@material-ui/core";
import SearchContainer from "../Search";
import { searchProjects } from "../../Axios";
import ProjectsTable from "../../components/ProjectTable";
import Fab from "@material-ui/core/Fab";
import { clean } from "../../util/functions";
import AddIcon from "@material-ui/icons/Add";
import { IProject } from "../../interfaces/IProject";
import { Link } from "react-router-dom";
import ExportButton from "../../components/ExportButton";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      width: "100%",
      margin: spacing(1)
    },
    button: {
      margin: spacing(1),
      width: "96%"
    },
    input: {
      margin: spacing(1),
      flexBasis: 300,
      flexGrow: 1,
      width: 300
    },
    table: {
      minWidth: 650
    },
    fab: {
      position: "fixed",
      bottom: spacing(2),
      right: spacing(2)
    },
    row: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    },
    menuRow: {
      height: 70
    }
  });

const MainContainer = props => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { classes } = props;

  const handleSearch = query => {
    clean(query);
    setLoading(true);
    searchProjects(query, (err, projects) => {
      setProjects(projects);
      setLoading(false);
    });
  };

  return (
    <>
      <div className={classes.row}>
        <SearchContainer handleSearch={handleSearch} />
        <ProjectsTable projects={projects} loading={loading} />
      </div>
      <div className={classes.menuRow}>
        <Link to={"/projects/create"}>
          <Fab color="primary" aria-label="Add" className={classes.fab}>
            <AddIcon />
          </Fab>
        </Link>
      </div>
    </>
  );
};

export default withStyles(styles)(MainContainer);
