import { Theme, createStyles } from "@material-ui/core";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    root: {
      minWidth: 300,
      overflow: "unset"
    },
    cardHeader: {
      color: palette.common.white,
      borderRadius: 4,
      padding: spacing(2),
      background: palette.primary.main
    },
    title: {
      color: "#fff"
    },
    container: {
      marginRight: -15,
      marginLeft: -15
    },
    form: {
      height: "90vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        borderBottom: "none"
      }
    },
    field: {
      marginRight: spacing(),
      marginLeft: spacing(),
      marginTop: spacing(2),
      marginBottom: spacing(2),
      flexBasis: 200,
      flexGrow: 1,
      display: "flex"
    },
    input: {
      color: palette.text.primary,
      "&:hover": {
        //borderBottom: "2px solid #1976d2",
        borderBottomColor: palette.error.main
      },
      "&:after": {
        borderColor: palette.primary.main
      },
      "&:before": {
        borderColor: palette.primary.main
      }
    },
    errorBox: {
      background: palette.error.main,
      minWidth: 300,
      margin: spacing()
    },
    errorContent: {},
    button: {
      width: "-webkit-fill-available",
      margin: spacing()
    },
    underline: {
      borderColor: palette.error.main
    },
    header: {}
  });

export default styles;
