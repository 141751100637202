import React, { useState, useEffect } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { deleteProject, getProject, createTeam, disconnectTeam, connectTeam } from "../../Axios";
import { capitalize, getDate, pad } from "../../util/functions";
import { styles } from "./ProjectStyles";
import { templates, api, intranetUrl } from "../../util/constants";
import { fields } from "./FormFields";
import { IProject } from "../../interfaces/IProject";
import Loader from "../../components/Loader";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  withStyles,
  Chip,
  ButtonGroup,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@material-ui/core";
import { Link, useHistory, useParams } from "react-router-dom";
import CreateTeamsModal from "../../components/CreateTeamsModal";
import ConnectTeamsModal from "../../components/ConnectTeamsModal";
import { DisconnectTeamsModal } from "../../components/DisconnectTeamsModal";

const Project = props => {
  const [project, setProject] = useState<IProject>();
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openTeamsModal, setOpenTeamsModal] = useState(false);
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const [openConnectTeamsModal, setOpenConnectTeamsModal] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    getProject(id, project => {
      setProject(project);
      setLoading(false);
    });
  }, [id]);

  const handleClick = e => setAnchorEl(e.currentTarget);
  const handleOpen = () => setOpenDelete(true);
  const handleClose = () => {
    setOpenDelete(false);
    setAnchorEl(null);
  };

  const handleDelete = (id = null) => {
    if (id) {
      deleteProject(id, status => {
        if (status === 200) {
          history.push("/");
        }
      });
    }
    setOpenDelete(false);
  };

  const toggleTeamsModal = () => {
    setOpenTeamsModal(!openTeamsModal);
  };

  const toggleConnectTeamsModal = () => {
    setOpenConnectTeamsModal(!openConnectTeamsModal);
  };

  const toggleDisconnectOpen = () => {
    setOpenDisconnectModal(!openDisconnectModal);
  };

  const handleCreateTeam = owners => {
    setLoading(true);
    createTeam(project, owners).then((team: any) => {
      setProject({ ...project, teams: { id: team.id, url: team.webUrl } });
      setOpenTeamsModal(false);
      setAnchorEl(null);
      setLoading(false);
    });
  };

  const handleConnectTeam = team => {
    setLoading(true);
    connectTeam(project, team)
      .then((data: IProject) => {
        setProject(data);
        setOpenConnectTeamsModal(false);
        setAnchorEl(null);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleDisconnectTeam = () => {
    setLoading(true);
    disconnectTeam(project)
      .then((data: IProject) => {
        setProject(data);
        setOpenDisconnectModal(false);
        setAnchorEl(null);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const { classes } = props;
  const open = Boolean(anchorEl);

  return (
    <div>
      <Loader loading={loading}>
        {project ? (
          <div>
            <div className={classes.root}>
              <Paper className={classes.paper} elevation={10}>
                <div>
                  <div className={classes.headerContainer}>
                    <div className={classes.alignLeft}>
                      <Chip
                        className={classes.chip}
                        size="medium"
                        label={
                          project.year &&
                          project.project_number &&
                          project.year +
                            "-" +
                            pad(project.project_number, 3) +
                            (project.owner === "Denmark" ? "DK" : "SE")
                        }
                        color={"primary"}
                      />
                      {project.opportunity_id && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={intranetUrl + "/opportunities/" + project.opportunity_id}
                        >
                          <Chip className={`${classes.chip} ${classes.blue}`} label="Opportunity" />
                        </a>
                      )}
                      <Chip className={classes.chip} size="medium" label={project.status} />
                      {project.teams?.url && (
                        <a target="_blank" rel="noopener noreferrer" href={project.teams?.url}>
                          <Chip color="primary" className={`${classes.chip} ${classes.purple}`} label="Teams" />
                        </a>
                      )}
                    </div>
                    <div className={classes.alignRight}>
                      <Typography className={classes.floatRight} variant={"body2"}>
                        {project.last_modified != null
                          ? `Last modified by ${project.last_modified?.name} ${getDate(project.last_modified?.date)}`
                          : `Created ${getDate(project.created_date)}`}
                      </Typography>
                      <IconButton
                        className={classes.floatRight}
                        aria-label="More"
                        aria-owns={open ? "long-menu" : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: "fit-content",
                            right: 30,
                            left: 0
                          }
                        }}
                      >
                        <Link to={"/projects/edit/" + project._id}>
                          <MenuItem>Edit</MenuItem>
                        </Link>
                        <MenuItem onClick={handleOpen}>Delete</MenuItem>
                        {project?.teams?.id
                          ? [
                              <a href={project.teams.url} target="_blank" rel="noopener noreferrer">
                                <MenuItem>Go to Team</MenuItem>
                              </a>,
                              <MenuItem onClick={toggleDisconnectOpen}>Disconnect Team</MenuItem>
                            ]
                          : [
                              <MenuItem onClick={toggleTeamsModal}>Create Team</MenuItem>,
                              <MenuItem onClick={toggleConnectTeamsModal}>Connect Team</MenuItem>
                            ]}
                      </Menu>
                    </div>
                  </div>
                </div>
                <div className={classes.row}>
                  <Typography variant="h5" className={classes.title} color={"primary"} component="h3">
                    {project.title}
                  </Typography>
                  <Typography variant="caption" display="block" gutterBottom>
                    Created {getDate(project.created_date)}
                  </Typography>
                  <Divider />
                </div>
                <div className={classes.infoRow}>
                  {fields
                    .filter(field => field.category === "details")
                    .map(field => (
                      <div className={classes.item} key={field.name}>
                        <Typography variant={"h6"} component="div">
                          {field.label ? field.label : capitalize(field.name.split("_").join(" "))}:
                        </Typography>
                        <Typography variant={"body1"}>
                          {field.type === "number" && project[field.name]
                            ? project[field.name].toLocaleString("en-GB")
                            : field.type === "date"
                            ? getDate(project[field.name])
                            : project[field.name]}
                        </Typography>
                      </div>
                    ))}
                </div>

                {["Won", "Lost", "Dropped"].includes(project?.status!) && project?.status_reason && (
                  <>
                    <div className={classes.row}>
                      <Typography variant="h6" className={classes.title}>
                        Reason {project.status}
                      </Typography>
                    </div>
                    <Table className={classes.table}>
                      <TableBody>
                        <TableRow>
                          <TableCell className={classes.tdcell}>{project.status_reason}</TableCell>
                          <TableCell className={classes.tdcell}>{project.status_comment}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                )}

                {!!project.total_contract_value?.length && (
                  <>
                    <div className={classes.row}>
                      <Typography variant="h6" className={classes.title}>
                        Total Contract Value
                      </Typography>
                    </div>

                    <Table className={classes.table}>
                      <TableBody>
                        {project?.total_contract_value?.map((cv, index) => (
                          <TableRow key={index}>
                            <TableCell className={classes.tdcell}>{cv.currency}</TableCell>
                            <TableCell className={classes.tdcell}>{cv.value}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                )}

                {fields
                  .filter(field => field.category === "textarea")
                  .map(field => (
                    <div className={classes.row} key={field.name}>
                      <Typography variant={"h6"}>{capitalize(field.name.split("_").join(" "))}:</Typography>
                      <Typography
                        variant={"body1"}
                        component="div"
                        dangerouslySetInnerHTML={{ __html: project[field.name] }}
                      ></Typography>
                    </div>
                  ))}

                {!!project.files.length && (
                  <div className={classes.row}>
                    <Typography variant={"h6"}>Related files:</Typography>
                    {project.files.map((file, index) => (
                      <div>
                        <a
                          className={classes.file}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={index}
                          href={api + "/uploads/" + project._id + "?load=" + encodeURIComponent(file)}
                          download
                        >
                          {file}
                        </a>
                      </div>
                    ))}
                  </div>
                )}

                <div className={classes.row}>
                  <ButtonGroup color="primary">
                    {templates.map(template => {
                      return (
                        <Button
                          key={template}
                          variant="contained"
                          href={api + "/projects/template/" + template + "?0=" + project._id}
                          download
                          color="primary"
                          className={classes.button}
                        >
                          {template}
                        </Button>
                      );
                    })}
                  </ButtonGroup>
                </div>
              </Paper>
            </div>
          </div> // end of inner div
        ) : null}

        <DeleteDialogue open={openDelete} project={project} handleClick={handleDelete} />
        <CreateTeamsModal open={openTeamsModal} toggleOpen={toggleTeamsModal} handleCreate={handleCreateTeam} />
        <ConnectTeamsModal
          open={openConnectTeamsModal}
          toggleOpen={toggleConnectTeamsModal}
          handleConnect={handleConnectTeam}
        />
        <DisconnectTeamsModal
          open={openDisconnectModal}
          toggleOpen={toggleDisconnectOpen}
          handleDisconnect={handleDisconnectTeam}
        />
      </Loader>
    </div> //end of outer div
  );
};

const DeleteDialogue = ({ open, project, handleClick }) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClick()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Deleting {project.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you wish to delete this post?
          <br />
          <br />
          Deleting a post is permanent.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClick()} color="primary">
          Cancel
        </Button>
        <Button onClick={() => handleClick(project._id)} color="primary" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(Project);
