import React, { useState, useEffect, FunctionComponent, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import Button from "@material-ui/core/Button/index";
import Divider from "@material-ui/core/Divider/index";
import Typography from "@material-ui/core/Typography/index";
import TextField from "@material-ui/core/TextField/index";
import styles from "./ProjectFormStyles";
import Paper from "@material-ui/core/Paper";
import Loader from "../../components/Loader";
import { fields, statusReasonArrays } from "./FormFields";
import BackIcon from "@material-ui/icons/ArrowBack";
import { createProject, getProject, getProjectFilters, updateProject } from "../../Axios";
import SelectComponent from "../../components/input/Select";
import DateComponent from "../../components/input/Date";
import TextComponent from "../../components/input/Text";
import NumberComponent from "../../components/input/Number";
import Editor from "../../components/Editor";
import { IProject } from "../../interfaces/IProject";
import { Container, Link, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";
import { IFilters } from "../../interfaces/IFilters";
import { useHistory, useParams } from "react-router-dom";
import { EStatus } from "../../enums/EStatus";
import FileUpload from "../../components/FileUpload";

const ProjectForm = props => {
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<any>({});
  const [project, setProject] = useState<IProject>({});
  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    getProjectFilters(filters => setFilters(filters));
    if (props.edit)
      getProject(id, project => {
        setProject(project);
        setLoading(false);
      });
    else setLoading(false);
  }, [id, props.edit]);

  const handleChange = useCallback(
    (property: string, value: string | string[] | number | number[] | Date) => {
      setProject({ ...project, [property]: value });
    },
    [project]
  );

  const handleSave = () => {
    setLoading(true);
    const files = project.filepond?.map(fileItem => fileItem.file.name);

    createProject({ ...project, files: files })
      .then((project: any) => {
        history.push("/projects/" + project._id);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const removeElement = ({ currency, value }) => {
    setProject({
      ...project,
      total_contract_value: project?.total_contract_value?.filter(
        cv => !(cv.currency === currency && cv.value === value)
      )
    });
  };

  const handleUpdate = () => {
    setLoading(true);
    const files = project.filepond?.map(fileItem => fileItem.file.name);

    updateProject({ ...project, files: files })
      .then((project: any) => {
        history.push("/projects/" + project._id);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const ComponentSwitch = field => {
    switch (field.type) {
      case "select":
        return (
          <SelectComponent
            key={field.name}
            field={field.name}
            options={filters?.[field.name]}
            handleChange={handleChange}
            value={project?.[field.name]}
            isClearable={field.clearable}
            isMulti={field.multi}
          />
        );
      case "editor":
        return <Editor key={field.name} field={field.name} value={project?.[field.name]} handleChange={handleChange} />;
      case "number":
        return (
          <NumberComponent
            key={field.name}
            field={field.name}
            handleChange={handleChange}
            format={field.format && field.format}
            value={project?.[field.name]}
          />
        );
      case "date":
        return (
          <DateComponent
            key={field.name}
            label={field.label}
            field={field.name}
            handleChange={handleChange}
            value={project?.[field.name]}
          />
        );
      case "text":
        return (
          <TextComponent
            key={field.name}
            field={field.name}
            handleChange={handleChange}
            value={project?.[field.name]}
          />
        );
    }
  };

  const { classes, edit } = props;

  return (
    <Loader loading={loading}>
      <div className={classes.root}>
        <Paper className={classes.paper} elevation={10}>
          <Container>
            <div>
              {edit && (
                <Link href={"/projects/" + project?._id}>
                  <Button
                    startIcon={<BackIcon />}
                    variant="outlined"
                    size="medium"
                    color="primary"
                    className={classes.margin}
                  >
                    Back to Project
                  </Button>
                </Link>
              )}

              <div className={classes.row}>
                <Typography variant="h4" className={classes.title}>
                  {edit ? "Edit" : "Create"} Project
                </Typography>
              </div>

              <Divider className={classes.divider} />

              <div className={classes.row}>
                <TextField
                  label="Title*"
                  className={classes.input}
                  variant="outlined"
                  value={project?.title ?? ""}
                  onChange={e => handleChange("title", e.target.value)}
                  fullWidth
                />
              </div>

              <div className={classes.row}>
                {fields.filter(field => field.category === "details").map(field => ComponentSwitch(field))}
              </div>

              {[EStatus.won, EStatus.dropped, EStatus.lost].some(status => status === project?.status) && (
                <>
                  <div className={classes.row}>
                    <Typography variant="h6" className={classes.title}>
                      Reason {project?.status}
                    </Typography>
                  </div>
                  <div className={classes.row}>
                    <SelectComponent
                      key={"status_reason"}
                      placeholder={"Reason"}
                      field={"status_reason"}
                      options={statusReasonArrays[project?.status!]?.map(option => {
                        return { value: option, label: option };
                      })}
                      handleChange={handleChange}
                      value={project?.["status_reason"] ?? ""}
                    />
                    <TextComponent
                      key={"status_comment"}
                      placeholder={"Comment"}
                      field={"status_comment"}
                      handleChange={handleChange}
                      value={project?.["status_comment"] ?? ""}
                    />
                  </div>
                </>
              )}

              <div className={classes.row}>
                <Typography variant="h6" className={classes.title}>
                  Total Contract Value
                </Typography>
              </div>

              {/* <TableContainer component={Paper}> */}

              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tdcell}>Currency</TableCell>
                    <TableCell className={classes.tdcell}>Contract Value</TableCell>
                    <TableCell className={classes.tdcell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {project?.total_contract_value?.map((cv, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.tdcell}>{cv.currency}</TableCell>
                      <TableCell className={classes.tdcell}>{cv.value}</TableCell>
                      <TableCell className={classes.tdcell} align="right">
                        <Button onClick={() => removeElement(cv)}> remove</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* </TableContainer> */}

              <ContractValue state={project} setState={setProject} filters={filters} classes={classes} />
              <div className={classes.row}>
                {fields.filter(field => field.category === "textarea").map(field => ComponentSwitch(field))}
              </div>

              <div style={{ margin: "24px 0px 24px 0px" }}>
                <FileUpload id={project._id} data={project.files} handleUpdate={handleChange} />
              </div>
            </div>
            <div style={{ justifyContent: "center" }}>
              <Button
                fullWidth
                color={"primary"}
                variant={"contained"}
                onClick={edit ? () => handleUpdate() : () => handleSave()}
              >
                Save
              </Button>
            </div>

            <div className={classes.bottomSpace} />
          </Container>
        </Paper>
      </div>
    </Loader>
  );
};

type Props = {
  state?: IProject;
  setState: Function;
  filters: IFilters;
  classes: any;
};

const ContractValue: FunctionComponent<Props> = ({ state, setState, filters, classes }) => {
  const [currency, setCurrency] = useState<string>("");
  const [contractValue, setContractValue] = useState<number>();

  const handleChange = useCallback(
    (property: string, value: string) => {
      if (property === "currency") setCurrency(value?.toUpperCase());
      if (property === "contract_value") setContractValue(parseFloat(value));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currency, contractValue]
  );

  const handleAdd = () => {
    let cv: { currency: string; value: number }[] = state?.total_contract_value || [];
    if (currency && contractValue) {
      if (cv.some(item => item.currency === currency)) {
      }

      cv.push({ currency: currency, value: contractValue });
      setState({
        ...state,
        total_contract_value: cv
      });
    }
  };

  return (
    <div className={classes.row}>
      <SelectComponent
        field={"currency"}
        value={currency}
        options={filters?.["currency"]}
        handleChange={handleChange}
      />
      <NumberComponent field={"contract_value"} handleChange={handleChange} value={contractValue} />
      <Button fullWidth className={classes.input} color={"primary"} variant={"contained"} onClick={handleAdd}>
        Add
      </Button>
    </div>
  );
};

withStyles(styles)(ContractValue);

export default withStyles(styles)(ProjectForm);
