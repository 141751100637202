import React, { useState, useEffect, FunctionComponent } from "react";
import { connect } from "react-redux";
import ProjectsTable from "../../components/ProjectTable";
import { getProjects } from "../../Axios";
import { templates, api } from "../../util/constants";
import Button from "@material-ui/core/Button";
import { IProject } from "../../interfaces/IProject";
import { createStyles, Theme, withStyles, ButtonGroup } from "@material-ui/core";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    row: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    },
    buttonRow: {
      marginTop: spacing()
    }
  });

const mapStateToProps = state => {
  return {
    savedProjects: state.savedProjects
  };
};

type Props = {
  savedProjects: string[];
  classes: any;
};

const Saved: FunctionComponent<Props> = ({ savedProjects, classes }) => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    savedProjects.length
      ? getProjects(projects => {
          setProjects(projects);
          setLoading(false);
        }, savedProjects)
      : setLoading(false);
  }, []);

  return (
    <div className={classes.row}>
      <ProjectsTable projects={projects} loading={loading} />
      {!!projects.length && (
        <ButtonGroup
          className={classes.buttonRow}
          variant="contained"
          color="primary"
          aria-label="contained primary button group"
        >
          {templates.map(template => {
            return (
              <Button
                variant="contained"
                // onClick={handleClick}
                href={
                  api +
                  "/projects/template/" +
                  template +
                  "?" +
                  projects
                    .map((project, index) => {
                      return index + "=" + project._id + "&";
                    })
                    .join("")
                }
                download
                color="primary"
              >
                {template}
              </Button>
            );
          })}
        </ButtonGroup>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(withStyles(styles)(Saved));
