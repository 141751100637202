import React, { useState, useCallback, FunctionComponent } from "react";
import { withStyles } from "@material-ui/core/styles/index";
import Card from "@material-ui/core/Card/index";
import CardContent from "@material-ui/core/CardContent/index";
import Button from "@material-ui/core/Button/index";
import Input from "@material-ui/core/Input/Input";
import Typography from "@material-ui/core/Typography/Typography";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import FormControl from "@material-ui/core/FormControl/FormControl";
import { login } from "../../Axios";
import { findGetParameter } from "../../util/functions";
import styles from "./LoginStyles";
import { useHistory } from "react-router-dom";

type Props = {
  classes: any;
};

const Login: FunctionComponent<Props> = ({ classes }) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const history = useHistory();

  const handleLogin = useCallback(
    event => {
      event.preventDefault();
      setLoading(true);
      login({ email: email, password: password })
        .then(() => {
          findGetParameter("redirect") ? history.push(findGetParameter("redirect")) : history.push("/");
        })
        .catch(err => setLoading(false));
    },
    [email, password]
  );

  return (
    <div className={classes.root}>
      <div className={classes.form}>
        {error && (
          <Card className={classes.errorBox}>
            <CardHeader
              disableTypography
              title={
                <Typography variant="body1" className={classes.title}>
                  {error}
                </Typography>
              }
            />
          </Card>
        )}

        <Card className={classes.root} color={"primary"}>
          <CardHeader
            disableTypography
            className={classes.cardHeader}
            title={
              <Typography variant="h5" className={classes.title}>
                Login
              </Typography>
            }
          />
          <CardContent>
            <form>
              <div>
                <FormControl className={classes.field}>
                  <Input
                    disabled={loading}
                    placeholder="Email"
                    className={classes.input}
                    value={email}
                    autoComplete={"email"}
                    onChange={e => setEmail(e.currentTarget.value)}
                    inputProps={{
                      "aria-label": "Email"
                    }}
                  />
                </FormControl>
              </div>
              <div>
                <FormControl className={classes.field}>
                  <Input
                    disabled={loading}
                    placeholder="Password"
                    className={classes.input}
                    type="password"
                    name={"password"}
                    autoComplete={"password"}
                    value={password}
                    onChange={e => setPassword(e.currentTarget.value)}
                    inputProps={{
                      "aria-label": "Password"
                    }}
                  />
                </FormControl>
              </div>

              <Button
                disabled={loading}
                type={"submit"}
                className={classes.button}
                size={"large"}
                color={"primary"}
                variant={"contained"}
                onClick={handleLogin}
              >
                Login
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default withStyles(styles)(Login);
