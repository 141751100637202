export const fields: {
  name: string;
  type: string;
  category: string;
  placeholder?: string;
  format?: boolean;
  multi?: boolean;
  clearable?: boolean;
}[] = [
  { name: "search", type: "text", category: "top" },

  { name: "year", type: "select", multi: true, clearable: true, category: "details" },
  { name: "owner", type: "select", multi: true, clearable: true, category: "details" },
  { name: "status", type: "select", multi: true, clearable: true, category: "details" },

  { name: "project_manager", type: "select", multi: true, clearable: true, category: "details" },
  { name: "partner_anchor", type: "select", multi: true, clearable: true, category: "details" },
  { name: "team_leader", type: "select", multi: true, clearable: true, category: "details" },
  { name: "staff_provided", type: "select", multi: true, clearable: true, category: "details" },

  { name: "start_date", type: "date", category: "details" },
  { name: "end_date", type: "date", category: "details" },
  // { name: "min_contract_signed_date", placeholder: "Min. Contract Signed Date", type: "date", category: "details" },
  // { name: "max_contract_signed_date", placeholder: "Max. Contract Signed Date", type: "date", category: "details" },

  { name: "country", type: "select", multi: true, clearable: true, category: "details" },
  { name: "sector", type: "select", multi: true, clearable: true, category: "details" },
  { name: "assignment_type", type: "select", multi: true, clearable: true, category: "details" },
  { name: "region", type: "select", multi: true, clearable: true, category: "details" },

  // TODO implement later
  // {name: "proportion", type: "number", category: "details"},
  // {name: "working_days", type: "number", category: "details"},
  { name: "donor", type: "select", multi: true, clearable: true, category: "details" },

  { name: "min_contract_value", placeholder: "Min. Contract Value (DKK)", type: "number", category: "details" },

  { name: "max_contract_value", placeholder: "Max. Contract Value (DKK)", type: "number", category: "details" },

  { name: "status_reason", placeholder: "Reason", type: "text", category: "reason" },
  { name: "status_comment", placeholder: "Comment", type: "text", category: "reason" }
];
