import React, { FunctionComponent, useState, useEffect, useCallback } from "react";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SelectComponent from "../../components/input/Select";
import DateComponent from "../../components/input/Date";
import TextComponent from "../../components/input/Text";
import NumberComponent from "../../components/input/Number";
import Editor from "../../components/Editor";
import withStyles from "@material-ui/core/styles/withStyles";
import { styles } from "./styles";
import { getProjectFilters } from "../../Axios";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import { ISearch } from "../../interfaces/ISearch";
import { fields } from "./FormFields";
import { IFilters } from "../../interfaces/IFilters";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { toggleCollapseSearch } from "../../redux/Actions";

const queryString = require("query-string");

const mapStateToProps = state => {
  return {
    collapseSearch: state.collapseSearch
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleCollapse() {
      dispatch(toggleCollapseSearch());
    }
  };
};

type Props = {
  handleSearch: Function;
  classes: any;
  collapseSearch?: boolean;
  toggleCollapse?: any;
};

const SearchContainer: FunctionComponent<Props> = ({ handleSearch, classes, collapseSearch, toggleCollapse }) => {
  const [search, setSearch] = useState<ISearch>({});
  const [filters, setFilters] = useState<IFilters>({});
  const history = useHistory();
  const query = useLocation().search;

  useEffect(() => {
    getProjectFilters(filters => {
      setFilters(filters);
    });
  }, []);

  useEffect(() => {
    const parsed = queryString.parse(query, { arrayFormat: "bracket" });
    setSearch(parsed);
    triggerSearch(parsed);
  }, [query]);

  const handleChange = useCallback(
    (property: string, value: string | string[] | number | number[] | Date) => {
      setSearch({ ...search, [property]: value });
    },
    [search]
  );

  const triggerSearch = params => {
    const searchString = params.search;
    if (searchString && RegExp(/(\d{4}-\d+)/).test(searchString)) {
      const tmp = searchString.split(/(\d{4})(?:-)(\d+)(DK|SE|dk|se)?(?:\w)*/).filter(Boolean);
      handleSearch({
        year: tmp[0],
        project_number: tmp[1]
      });
    } else {
      handleSearch(params);
    }
  };

  const handleClick = () => {
    const query = queryString.stringify(search, { arrayFormat: "bracket" });

    history.push("?" + query);
  };

  const ComponentSwitch = field => {
    switch (field.type) {
      case "select":
        return (
          <SelectComponent
            key={field.name}
            field={field.name}
            placeholder={field.placeholder}
            options={filters?.[field.name]}
            handleChange={handleChange}
            value={search?.[field.name]}
            isClearable={field.clearable}
            isMulti={field.multi}
          />
        );
      case "editor":
        return (
          <Editor
            key={field.name}
            field={field.name}
            value={search ? [field.name] : null}
            handleChange={handleChange}
          />
        );
      case "number":
        return (
          <NumberComponent
            key={field.name}
            field={field.name}
            placeholder={field.placeholder}
            handleChange={handleChange}
            format={field.format && field.format}
            value={search?.[field.name]}
          />
        );
      case "date":
        return (
          <DateComponent
            key={field.name}
            field={field.name}
            label={field.placeholder}
            handleChange={handleChange}
            value={search?.[field.name]}
          />
        );
      case "text":
        return (
          <TextComponent
            key={field.name}
            field={field.name}
            placeholder={field.placeholder}
            handleChange={handleChange}
            value={search?.[field.name]}
          />
        );
    }
  };

  return (
    <div className="row">
      <Paper className={classes.paper}>
        <div className={classes.row}>
          <TextField
            id={"search"}
            placeholder={'Search text or Project number "1234-567"'}
            onKeyDown={e => e.key === "Enter" && handleClick()}
            className={classes.textSearch}
            fullWidth
            variant="outlined"
            value={search?.["search"] ?? ""}
            onChange={e => handleChange(e.target.id, e.target.value)}
          />
          <IconButton onClick={toggleCollapse} className={classes.margin} size="medium">
            <ExpandMoreIcon fontSize="inherit" />
          </IconButton>
        </div>

        <Collapse in={collapseSearch} collapsedHeight={0}>
          <div className={classes.row}>
            {fields.filter(field => field.category === "details").map(field => ComponentSwitch(field))}
          </div>

          <div style={{ justifyContent: "center", margin: "8px 8px" }}>
            <Button fullWidth color={"primary"} variant={"contained"} onClick={handleClick}>
              Search
            </Button>
          </div>
        </Collapse>
      </Paper>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchContainer));
