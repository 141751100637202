import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import React, { Component } from "react";
import { clearMessage } from "../redux/Actions";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const styles = theme => ({
  ERROR: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white
  },
  SUCCESS: {
    backgroundColor: theme.palette.status.won,
    color: theme.palette.common.white
  },
  standard: {
    backgroundColor: "black"
  }
});

const mapStateToProps = state => {
  return {
    messages: state.messages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClearMessage(index) {
      dispatch(clearMessage(index));
    }
  };
};

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleClose = () => {
    this.setState({ open: false }, () => this.props.onClearMessage(0));
  };

  render() {
    const { classes, messages, onClearMessage } = this.props;

    return messages.map((message, index) => (
      <Snackbar
        key={index}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        className={classes[message.variant]}
        open={!!message}
        autoHideDuration={5000}
        onClose={() => onClearMessage(index)}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
      >
        <SnackbarContent
          className={classes[message.variant || "standard"]}
          aria-describedby="client-snackbar"
          message={<span id="client-snackbar">{message.message}</span>}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={() => onClearMessage(index)}>
              <Close />
            </IconButton>
          ]}
        />
      </Snackbar>
    ));
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Notification));
