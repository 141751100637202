//get formatted date from ISO timestamp
import NumberFormat from "react-number-format";
import React from "react";

export function pad(number: string | number, size?: number): string {
  let s = String(number);
  while (s.length < (size || 3)) {
    s = "0" + s;
  }
  return s;
}

export function getProjectNumber(number: number | string, year: number | string, owner: string) {
  return year + "-" + pad(number) + (owner === "Denmark" ? "DK" : "SE");
}

export function capitalize(string): string {
  return string.replace(/(?:^|\s)\S/g, function(a: string): string {
    return a.toUpperCase();
  });
}

export function getDate(date): string {
  if (date) {
    let day, month: string;
    let today = new Date(date);
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    day = (dd < 10 ? "0" : "") + dd;
    month = (mm < 10 ? "0" : "") + mm;
    date = day + "/" + month + "/" + yyyy;
    return date;
  } else return "";
}

// Count comments and subcomments on posts
export function commentCounter(comments) {
  if (comments.length) {
    let commentsTotal = comments.length;
    for (let i = 0; i < comments.length; i++) {
      commentsTotal = commentsTotal + comments[i].subcomments.length;
    }
    return commentsTotal;
  } else {
    return "0";
  }
}

// get excerpt from HTML
export function getExcerpt(string, length) {
  if (string) {
    string = string.replace(/<\s*a[^>]*>/g, "");
    string = string.replace(/(<([^>]+)>)/gi, " ");
    string = string.replace(/&nbsp;/gi, "");

    // string = string.replace("&nbsp;"," ");
    // string = strip(string)
    if (string.length > length) {
      let str = string.split(" ");
      let excerpt = "";
      let i = 0;
      while (excerpt.length < length) {
        excerpt += str[i] + " ";
        i++;
      }
      return excerpt.trim() + "...";
    } else {
      return string;
    }
  } else {
    return "";
  }
}

export function getInitials(string) {
  let names = string.toString().split(" "),
    initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function findGetParameter(parameterName): string {
  let result: string = "",
    tmp: string[];
  window.location.search
    .substr(1)
    .split("&")
    .forEach(function(item) {
      tmp = item.split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    });
  return result;
}

export function clean(obj) {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName].length === 0 ||
      obj[propName] == null ||
      obj[propName] === ""
    ) {
      delete obj[propName];
    }
  }
}

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...rest } = props;
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
    />
  );
}

export function stripHTML(string) {
  if (string) {
    string = string.replace(/<\s*a[^>]*>/g, "");
    string = string.replace(/(<([^>]+)>)/gi, " ");
    string = string.replace(/&nbsp;/gi, " ");
    string = string.replace(/\s\s+/g, " ");

    return string;
  }
  return "";
}
