import { EStatusReason } from "../../enums/EStatusReason";
import { EStatus } from "../../enums/EStatus";
export const fields: {
  name: string;
  label?: string;
  type: string;
  category: string;
  format?: boolean;
  multi?: boolean;
  clearable?: boolean;
}[] = [
  { name: "title", type: "text", category: "top" },

  { name: "project_number", type: "number", format: false, category: "details" },
  { name: "year", type: "select", multi: false, clearable: false, category: "details" },
  { name: "owner", type: "select", multi: false, clearable: false, category: "details" },
  { name: "status", type: "select", multi: false, clearable: false, category: "details" },

  { name: "project_manager", type: "select", multi: false, category: "details" },
  { name: "partner_anchor", type: "select", multi: false, category: "details" },
  { name: "team_leader", type: "select", multi: false, category: "details" },
  { name: "staff_provided", type: "select", multi: true, category: "details" },

  { name: "start_date", label: "Start Date (Contract Signed)", type: "date", category: "details" },
  { name: "end_date", type: "date", category: "details" },
  // { name: "contract_signed_date", type: "date", category: "details" },

  { name: "country", type: "select", multi: true, category: "details" },
  { name: "sector", type: "select", multi: true, category: "details" },
  { name: "assignment_type", type: "select", multi: true, category: "details" },
  { name: "region", type: "select", multi: false, category: "details" },

  { name: "proportion", type: "number", category: "details" },
  { name: "working_days", type: "number", category: "details" },
  { name: "donor", type: "select", multi: false, clearable: false, category: "details" },

  // TODO add total_contract_value

  { name: "general_description", type: "editor", category: "textarea" },
  { name: "types_of_services", type: "editor", category: "textarea" },
  { name: "note", type: "editor", category: "textarea" },

  { name: "status_reason", type: "text", category: "reason" },
  { name: "status_comment", type: "text", category: "reason" }
];

export const statusReasonArrays = {
  [EStatus.won]: [
    EStatusReason.directAward,
    EStatusReason.price,
    EStatusReason.team,
    EStatusReason.methodology,
    EStatusReason.noSpecifiedReason
  ],
  [EStatus.lost]: [
    EStatusReason.price,
    EStatusReason.team,
    EStatusReason.methodology,
    EStatusReason.notShortlisted,
    EStatusReason.dq,
    EStatusReason.noSpecifiedReason
  ],
  [EStatus.dropped]: [EStatusReason.price, EStatusReason.team]
};
