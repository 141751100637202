import { Theme, createStyles } from "@material-ui/core";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    table: {
      width: "100%"
    }
  });

export default styles;
