import React from "react";
import "./App.css";
import theme from "./assets/Theme.js";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import Header from "./components/Header";
import Project from "./pages/Project";
import Notification from "./components/Notification";
import NotFound from "./pages/NotFound";
import Saved from "./pages/Saved";
import ProjectForm from "./pages/Project/ProjectForm";
import { connect } from "react-redux";
import Login from "./pages/Login";
import MainContainer from "./pages/Main";

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const App = props => {
  const { user } = props;
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          <PrivateRoute user={user} exact path="/">
            <div>
              <Header />
              <MainContainer />
            </div>
          </PrivateRoute>
          <PrivateRoute user={user} exact path="/projects/create">
            <div>
              <Header />
              <ProjectForm />
            </div>
          </PrivateRoute>
          <PrivateRoute user={user} exact path="/projects/edit/:id">
            <div>
              <Header />
              <ProjectForm edit={true} />
            </div>
          </PrivateRoute>
          <PrivateRoute user={user} exact path="/projects/:id">
            <div>
              <Header />
              <Project />
            </div>
          </PrivateRoute>
          <PrivateRoute user={user} exact path="/saved">
            <div>
              <Header />
              <Saved />
            </div>
          </PrivateRoute>
          <Route exact path="/login">
            <div>
              <Header />
              <Login />
            </div>
          </Route>
          <Route component={NotFound} />
        </Switch>
        <Notification />
      </Router>
    </MuiThemeProvider>
  );
};

function PrivateRoute(props) {
  return (
    <Route {...props}>
      {props.user ? (
        props.children
      ) : (
        <Redirect
          to={{
            pathname: "/login"
            // state: { from: location }
          }}
        />
      )}
    </Route>
  );
}

export default connect(mapStateToProps)(App);
