import { constants as Actions } from "./Actions";
import { combineReducers } from "redux";

const user = (state = null, action) => {
  switch (action.type) {
    case Actions.SET_USER:
      return action.payload;
    case Actions.CLEAR_USER:
      return null;
    default:
      return state;
  }
};

const errors = (state = [], action) => {
  switch (action.type) {
    case Actions.ADD_ERROR:
      return [...state, action.payload];
    case Actions.CLEAR_ERROR:
      return state.filter((message, index) => index !== action.payload);
    default:
      return state;
  }
};

const messages = (state = [], action) => {
  switch (action.type) {
    case Actions.ADD_MESSAGE:
      return [...state, action.payload];
    case Actions.CLEAR_MESSAGE:
      return state.filter((message, index) => index !== action.payload);
    default:
      return state;
  }
};

const savedProjects = (state = [], action) => {
  switch (action.type) {
    case Actions.ADD_SAVED:
      if (state.includes(action.payload)) {
        return state;
      } else {
        return [...state, action.payload];
      }
    case Actions.CLEAR_SAVED:
      return state.filter(saved => saved !== action.payload);
    case Actions.CLEAR_ALL_SAVED:
      return [];
    default:
      return state;
  }
};

const collapseSearch = (state = false, action) => {
  return action.type === Actions.TOGGLE_COLLAPSE_SEARCH ? !state : state;
};

export default combineReducers({ errors, messages, savedProjects, user, collapseSearch });
