import { Theme, createStyles } from "@material-ui/core";

const styles = ({ palette, spacing }: Theme) =>
  createStyles({
    roor: {
      padding: spacing() * 3
    },
    row: {
      // marginRight: -15,
      // marginLeft: -15,
      minHeight: 70,
      display: "flex",
      flexWrap: "wrap"
    },
    paper: {
      padding: spacing(4)
    },
    bottomSpace: {
      padding: 50
    },
    appBar: {
      position: "relative"
    },
    flex: {
      flex: 1
    },
    fabRow: {
      marginTop: 70
    },

    input: {
      margin: spacing(),
      flexBasis: 250,
      flexGrow: 1
    },
    title: {
      marginTop: 20,
      marginRight: 8,
      marginBottom: 20,
      marginLeft: 8
    },
    divider: {
      //marginTop: 20,
      marginBottom: 20
    },
    overlay: {
      position: "fixed",
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 2
    },
    tdcell: {
      width: "33.33%"
    }
  });

export default styles;
