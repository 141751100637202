import { createStyles, Theme } from "@material-ui/core";

export const styles = ({ spacing }: Theme) => createStyles({
    root: {
        width: '100%',
        marginTop: spacing(2)
    },
    paper: {
        padding: spacing(2),
        marginTop: spacing(2),
        flexGrow: 1
    },
    button: {
        margin: spacing(1),
        width: '96%'
    },
    row: {
        minHeight: 70,
        display: 'flex',
        flexWrap: 'wrap',
    },
    textSearch: {
        margin: spacing(1),
        flexBasis: 300,
        flexGrow: 1,
    },
    input: {
        margin: spacing(1),
        flexBasis: 300,
        flexGrow: 1,
        width: 300
    },
    table: {
        minWidth: 650,
    },

    fab: {
        position: 'absolute',
        bottom: spacing(2),
        right: spacing(2),
    },

});

